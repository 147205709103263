export default class ProfileFunctionality {
    constructor() {
        this.profileData = {
            userId: 0,
            courses: {
                programmType: 'courses',
                programms: {},
            },
            lectures: {
                programmType: 'lectures',
                programms: {},
            },
        };
        this.initedPlayer = null;
    }
    init() {
        this.playVideoByClickInit();
        this.createProfileVideoData('courses');
        this.createProfileVideoData('lectures');
        this.playNextVideo();
        this.editFormFieldAddEvent();
        this.addEventfetchUserDataForm();
        this.initPlayerOnOpenBlock();
    }
    loadDataAndPlayVideo(playBtnData) {
        var _a, _b, _c, _d, _e;
        if (!playBtnData)
            return;
        const containerId = (_a = playBtnData.dataset) === null || _a === void 0 ? void 0 : _a.video_container_id;
        const videoTitle = (_b = playBtnData.dataset) === null || _b === void 0 ? void 0 : _b.video_title;
        const videoId = (_c = playBtnData.dataset) === null || _c === void 0 ? void 0 : _c.video_id;
        const videoPauseTime = (_e = parseFloat((_d = playBtnData.dataset) === null || _d === void 0 ? void 0 : _d.video_pause_time)) !== null && _e !== void 0 ? _e : null;
        const videoContainer = document.querySelector(`[data-video_container_id="${containerId}"]`);
        const blockVideoWrapper = videoContainer.closest('.js-block-video');
        const videoTitleContainer = blockVideoWrapper === null || blockVideoWrapper === void 0 ? void 0 : blockVideoWrapper.querySelector('.js-video-title');
        if (videoTitleContainer) {
            videoTitleContainer.innerText = `${videoTitle}`;
        }
        if (!videoContainer)
            return;
        videoContainer.dataset.video_id = playBtnData.dataset.video_id;
        this.loadPDFButtons(playBtnData);
        const onPauseCallback = (pauseInfo) => {
            playBtnData.dataset.video_pause_time = pauseInfo.seconds;
        };
        this.initVimeoPlayer(this.initedPlayer, videoId, true, videoPauseTime, onPauseCallback);
    }
    loadPDFButtons(playBtnData) {
        if (!playBtnData || !playBtnData.dataset || !playBtnData.dataset.video_files) {
            console.error('Video files data is missing.');
            return;
        }
        const videoFiles = JSON.parse(playBtnData.dataset.video_files);
        const parentBlock = playBtnData.closest('.js-programm-block');
        console.log('parentBlock', parentBlock);
        let html = '';
        videoFiles &&
            videoFiles.forEach((item) => {
                if (!item.file && !item.file)
                    return;
                html += `<a target="_blank" href="${item.file}" class="green-transparent">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.06973 15.441C7.06973 15.0424 6.79316 14.8047 6.30519 14.8047C6.10592 14.8047 5.97098 14.8244 5.90039 14.8432V16.122C5.98397 16.1408 6.08671 16.1471 6.22833 16.1471C6.74846 16.1471 7.06973 15.8843 7.06973 15.441Z" fill="white"/>
                            <path d="M10.0907 14.8179C9.87221 14.8179 9.73099 14.8367 9.64746 14.8563V17.6885C9.73104 17.7081 9.86599 17.7081 9.98799 17.7081C10.8745 17.7144 11.4527 17.2264 11.4527 16.1924C11.4589 15.2933 10.9321 14.8179 10.0907 14.8179Z" fill="white"/>
                            <path d="M15.703 0H6.06363C4.65541 0 3.50927 1.14694 3.50927 2.55436V12H3.25978C2.69141 12 2.23047 12.4604 2.23047 13.0293V19.2717C2.23047 19.8405 2.69136 20.3009 3.25978 20.3009H3.50927V21.4456C3.50927 22.8546 4.65541 24 6.06363 24H19.2161C20.6235 24 21.7698 22.8545 21.7698 21.4456V6.0455L15.703 0ZM4.93078 14.1558C5.23243 14.1049 5.65644 14.0664 6.25383 14.0664C6.85754 14.0664 7.28782 14.1817 7.57693 14.4131C7.8531 14.6313 8.03947 14.9913 8.03947 15.415C8.03947 15.8386 7.89825 16.1988 7.6413 16.4427C7.30709 16.7573 6.81284 16.8986 6.23467 16.8986C6.10599 16.8986 5.99065 16.8922 5.90046 16.8797V18.4276H4.93078V14.1558ZM19.2161 22.4356H6.06363C5.51836 22.4356 5.07434 21.9916 5.07434 21.4456V20.3009H17.3352C17.9036 20.3009 18.3645 19.8405 18.3645 19.2717V13.0293C18.3645 12.4604 17.9036 12 17.3352 12H5.07434V2.55436C5.07434 2.00989 5.51841 1.56587 6.06363 1.56587L15.1178 1.55641V4.90314C15.1178 5.88068 15.9109 6.67459 16.8892 6.67459L20.1677 6.66518L20.2046 21.4455C20.2046 21.9916 19.7614 22.4356 19.2161 22.4356ZM8.66473 18.408V14.1558C9.02438 14.0986 9.49314 14.0664 9.98783 14.0664C10.81 14.0664 11.3431 14.2139 11.7608 14.5285C12.2104 14.8627 12.4928 15.3954 12.4928 16.1603C12.4928 16.9887 12.1911 17.5607 11.7733 17.9136C11.3175 18.2925 10.6236 18.4722 9.77598 18.4722C9.26834 18.4722 8.90869 18.4401 8.66473 18.408ZM15.6748 15.8905V16.6867H14.1203V18.4276H13.1376V14.0986H15.7838V14.9011H14.1203V15.8905H15.6748Z" fill="white"/>
                        </svg>
                        ${item.file_name}
                    </a>`;
            });
        if (parentBlock) {
            const videoembedFiles = parentBlock.querySelector('.js-video-embed-files');
            console.log('videoembedFiles', videoembedFiles);
            videoembedFiles.innerHTML = html;
        }
    }
    playVideoByClickInit() {
        const playVideoBtns = document.querySelectorAll('.js-play-video-btn');
        if (!playVideoBtns)
            return;
        const removeAllActiveBtns = () => {
            playVideoBtns.forEach((el) => el.classList.remove('playing-video'));
        };
        playVideoBtns.forEach((el) => {
            const button = el;
            button.addEventListener('click', (e) => {
                e.stopPropagation();
                removeAllActiveBtns();
                button.classList.add('playing-video');
                this.loadDataAndPlayVideo(button);
            });
        });
    }
    saveVideoTimeData(videoContainer, videoParams, learninMaterialType) {
        var _a, _b;
        if (!videoContainer && !videoParams && !learninMaterialType)
            return;
        const videoDuration = videoParams.duration;
        const videoPauseTime = videoParams.seconds;
        const programmId = (_a = videoContainer.dataset.video_container_id) === null || _a === void 0 ? void 0 : _a.split('_')[0];
        const shortBlockId = (_b = videoContainer.dataset.video_container_id) === null || _b === void 0 ? void 0 : _b.split('_')[1];
        const videoId = videoContainer.dataset.video_id;
        let viewed = false;
        if (videoParams.percent) {
            viewed = videoParams.percent >= 0.9;
        }
        const currentProgrammPath = this.profileData[learninMaterialType].programms[programmId];
        const currentBlockPath = currentProgrammPath === null || currentProgrammPath === void 0 ? void 0 : currentProgrammPath.blocks[shortBlockId];
        currentBlockPath.fullBlockId = videoContainer.dataset.video_container_id;
        currentBlockPath.videos[videoId] = Object.assign(Object.assign({}, currentBlockPath.videos[videoId]), { videoDuration,
            videoPauseTime, isVideoViewed: viewed });
        console.log('related profileData', this.profileData);
        this.changeBlockStatus(currentBlockPath);
        this.changePassedBlocksCount(currentProgrammPath);
        this.fetchDataToBackend(this.profileData);
        this.visualUpdateProgressBar(videoContainer, learninMaterialType);
    }
    createProfileVideoData(learninMaterialType) {
        const mainContainer = document.querySelector(`#${learninMaterialType}`);
        if (!mainContainer)
            return;
        const playVideoBtns = mainContainer.querySelectorAll('.js-play-video-btn');
        if (mainContainer && mainContainer.dataset.user_id) {
            this.profileData.userId = +mainContainer.dataset.user_id;
        }
        playVideoBtns &&
            playVideoBtns.forEach((el) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                const button = el;
                const programmId = (_b = (_a = button.dataset) === null || _a === void 0 ? void 0 : _a.video_container_id) === null || _b === void 0 ? void 0 : _b.split('_')[0];
                const blockId = (_d = (_c = button.dataset) === null || _c === void 0 ? void 0 : _c.video_container_id) === null || _d === void 0 ? void 0 : _d.split('_')[1];
                const videoId = (_e = button.dataset) === null || _e === void 0 ? void 0 : _e.video_id;
                const videoDuration = (_f = button.dataset) === null || _f === void 0 ? void 0 : _f.video_duration;
                const videoPauseTime = (_g = button.dataset) === null || _g === void 0 ? void 0 : _g.video_pause_time;
                const videoIsViewed = (_h = button.dataset) === null || _h === void 0 ? void 0 : _h.video_viewed;
                const blocksPassedCount = (_j = button.dataset) === null || _j === void 0 ? void 0 : _j.passed_blocks_count;
                if (!programmId || !blockId || !videoId)
                    return;
                if (!this.profileData[learninMaterialType].programms[programmId]) {
                    this.profileData[learninMaterialType].programms[programmId] = {
                        programmId: +programmId.split('-')[1] || null,
                        blocksPassed: blocksPassedCount,
                        blocks: {},
                    };
                }
                if (!this.profileData[learninMaterialType].programms[programmId].blocks[blockId]) {
                    const currentBlock = this.getCurrentBlock(programmId, blockId);
                    this.profileData[learninMaterialType].programms[programmId].blocks[blockId] = {
                        blockStatus: ((_k = currentBlock === null || currentBlock === void 0 ? void 0 : currentBlock.dataset) === null || _k === void 0 ? void 0 : _k.block_status) || null,
                        videos: {},
                    };
                }
                const currentProgrammPath = this.profileData[learninMaterialType].programms[programmId];
                const currentBlockPath = currentProgrammPath === null || currentProgrammPath === void 0 ? void 0 : currentProgrammPath.blocks[blockId];
                currentBlockPath.videos[videoId] = {
                    videoId: videoId || null,
                    videoDuration: videoDuration || null,
                    videoPauseTime: videoPauseTime || null,
                    isVideoViewed: videoIsViewed || '',
                };
            });
        console.log('created profileData', this.profileData);
    }
    changeBlockStatus(currentBlockObject) {
        if (!currentBlockObject)
            return;
        let status = '';
        const videosArray = Object.values(currentBlockObject.videos);
        const isBlockPassed = videosArray.every((video) => video.isVideoViewed);
        const isBlockNotPassed = videosArray.every((video) => !video.isVideoViewed);
        if (isBlockPassed) {
            status = 'passed';
        }
        else if (isBlockNotPassed) {
            status = 'not-passed';
        }
        else {
            status = 'in-progress';
        }
        currentBlockObject.blockStatus = status;
        this.visualUpdateBlockStatus(currentBlockObject.fullBlockId, status);
    }
    visualUpdateBlockStatus(blockContainerId, status) {
        const queryStr = `.js-programm-block[data-block_id="${blockContainerId}"]`;
        const blockElem = document.querySelector(queryStr);
        if (blockElem) {
            blockElem.dataset.block_status = status;
            const statusContainer = blockElem.querySelector('.js-block-status');
            if (statusContainer) {
                statusContainer.classList.remove('passed');
                statusContainer.classList.remove('not-passed');
                statusContainer.classList.remove('in-progress');
                statusContainer.classList.add(status);
            }
        }
    }
    visualUpdateProgressBar(videoContainer, learninMaterialType) {
        const programmIdStr = videoContainer === null || videoContainer === void 0 ? void 0 : videoContainer.dataset.video_container_id.split('_')[0];
        const programmItem = document.querySelector(`[data-programm_id="${programmIdStr}"]`);
        const progressBarWrap = programmItem.querySelector('.js-progress-info');
        if (!this.profileData[learninMaterialType].programms)
            return;
        const programmsData = this.profileData[learninMaterialType].programms;
        if (!programmsData[programmIdStr].blocks)
            return;
        const blocksData = programmsData[programmIdStr].blocks;
        const blocksCount = Object.keys(blocksData).length;
        const passedBlocksCount = programmsData[programmIdStr].blocksPassed;
        const passedBlocksSpan = progressBarWrap.querySelector('.js-passed-blocks-span');
        const progressBar = progressBarWrap.querySelector('.js-progress-bar');
        if (progressBar) {
            progressBar.innerHTML = this.generateProgressBar(161, blocksCount, passedBlocksCount);
        }
        if (passedBlocksSpan) {
            passedBlocksSpan.innerText = `${passedBlocksCount}`;
        }
    }
    generateProgressBar(totalWidth, blocksCount, blocksPassed) {
        let blockWidth = 0;
        if (blocksCount !== 0) {
            blockWidth = totalWidth / blocksCount;
        }
        let svg = '';
        svg += `<rect width="${totalWidth}" height="5" transform="matrix(1 0 0 -1 0 5)" fill="#131614" />`;
        for (let i = 0; i < blocksPassed; i++) {
            const xPosition = i * blockWidth;
            svg += `<rect width="${blockWidth}" height="5" transform="matrix(1 0 0 -1 ${xPosition} 5)" fill="#53F07F" />`;
        }
        return svg;
    }
    changePassedBlocksCount(currentProgrammObject) {
        if (!currentProgrammObject)
            return;
        const blocksArray = Object.values(currentProgrammObject.blocks);
        const countOfPassedBlocks = blocksArray.filter((block) => block.blockStatus === 'passed');
        currentProgrammObject.blocksPassed = countOfPassedBlocks.length;
    }
    playNextVideo() {
        const playNextBtns = document.querySelectorAll('.js-next-video-btn');
        playNextBtns &&
            playNextBtns.forEach((el) => {
                const btn = el;
                btn.addEventListener('click', (e) => {
                    e.stopPropagation();
                    const videoBlock = btn.closest('.js-programm-block');
                    const playVideoBtns = videoBlock.querySelectorAll('.js-play-video-btn');
                    const playVideoBtn = videoBlock.querySelector('.js-play-video-btn.playing-video');
                    const nextPLayBtn = playVideoBtn === null || playVideoBtn === void 0 ? void 0 : playVideoBtn.nextSibling;
                    if (nextPLayBtn) {
                        playVideoBtns &&
                            playVideoBtns.forEach((el2) => {
                                const btn2 = el2;
                                btn2.classList.remove('playing-video');
                            });
                        nextPLayBtn.classList.add('playing-video');
                        this.loadDataAndPlayVideo(nextPLayBtn);
                    }
                });
            });
    }
    fetchDataToBackend(profileDataParams) {
        if (!profileDataParams)
            return;
        fetch(`${var_from_php.ajax_url}?action=save_video_data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(profileDataParams),
        });
    }
    getCurrentBlock(programmId, blockId) {
        const blockIdstring = `${programmId}_${blockId}`;
        const currentBlock = document.querySelector(`[data-block_id="${blockIdstring}"]`);
        return currentBlock || null;
    }
    addEventfetchUserDataForm() {
        const form = document.querySelector('.js-user-info-form');
        if (!form)
            return;
        form &&
            form.addEventListener('submit', (e) => {
                e.preventDefault();
                const formData = new FormData(form);
                fetch(`${var_from_php.ajax_url}?action=update_user_data`, {
                    method: 'POST',
                    body: formData,
                })
                    .then((res) => res.json())
                    .then((res) => {
                    console.log(res.data);
                    const respContainer = form.querySelector('.js-response-container');
                    const additionalClass = res.success ? 'success' : 'error';
                    if (respContainer) {
                        const paragrahp = document.createElement('p');
                        paragrahp.classList.add(additionalClass);
                        paragrahp.innerText = res.data;
                        respContainer.appendChild(paragrahp);
                    }
                });
            });
    }
    editFormFieldAddEvent() {
        const editBtns = document.querySelectorAll('.js-edit-btn');
        const inpus = document.querySelectorAll('input[type="text"]');
        const setInputDefaultState = (el) => {
            const input = el;
            input.classList.remove('focus');
        };
        editBtns &&
            editBtns.forEach((el) => {
                const btn = el;
                btn.addEventListener('click', (e) => {
                    e.preventDefault();
                    inpus && inpus.forEach((el2) => setInputDefaultState(el2));
                    const parentWrapper = btn.closest('.js-inner-input-wrapper');
                    const input = parentWrapper === null || parentWrapper === void 0 ? void 0 : parentWrapper.querySelector('input[type="text"]');
                    input.classList.add('focus');
                });
            });
    }
    initVimeoPlayer(playerEl, videoId, loadVideo = false, start = 0, cb = (pauseInfo) => { }) {
        let player;
        if (!loadVideo) {
            player = new Vimeo.Player(playerEl, {
                id: videoId,
            });
            start && player.setCurrentTime(start);
        }
        else {
            player = playerEl;
            player.loadVideo(videoId).then(() => {
                start && player.setCurrentTime(start);
            });
        }
        player.off('pause', cb);
        player.on('pause', cb);
        return player;
    }
    initPlayerOnOpenBlock() {
        const blocks = document.querySelectorAll('.js-programm-block');
        blocks &&
            blocks.forEach((el) => {
                const block = el;
                block.addEventListener('click', () => {
                    const player = block.querySelector(`[data-video_container_id="${block.dataset.block_id}"]`);
                    const firstPlayBtnInBlcok = block.querySelector('.js-play-video-btn');
                    const videoStartTime = (firstPlayBtnInBlcok === null || firstPlayBtnInBlcok === void 0 ? void 0 : firstPlayBtnInBlcok.dataset.video_pause_time)
                        ? +firstPlayBtnInBlcok.dataset.video_pause_time
                        : 0;
                    if (!player || !firstPlayBtnInBlcok)
                        return;
                    firstPlayBtnInBlcok.classList.add('playing-video');
                    const onPauseCallback = (pauseInfo) => {
                        const parentProgramm = block.closest('.js-programm');
                        const playVideoBtn = block.querySelector(`#video-btn-${player.dataset.video_id}`);
                        if (playVideoBtn) {
                            playVideoBtn.dataset.video_pause_time = `${pauseInfo.seconds}`;
                        }
                        this.saveVideoTimeData(player, pauseInfo, parentProgramm.id);
                    };
                    this.initedPlayer = this.initVimeoPlayer(player, player.dataset.video_id, false, videoStartTime, onPauseCallback);
                });
            });
    }
}
